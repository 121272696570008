import React from "react";
import { useTranslation } from "react-i18next";
import { Formik, Form, Field, ErrorMessage } from "formik";
import ReactModal from "react-modal";
import { MdOutlineClose } from "react-icons/md";
import {
  errorMessageStyle,
  formButton,
  formInput,
} from "../../../ui-elements/formStyles/formStyle";
import { validationSchema } from "./validation/validationSchema";
import "./partnersModal.css";
import { becomeAPartner } from "../../../axios/api/partnersApi";
import {
  changePopUpStatus,
  setRequestStatus,
} from "../../../redux/slices/popUp/popUpSlice";
import { useDispatch } from "react-redux";

ReactModal.setAppElement("#root");

const PartnersModal = ({ showModal, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleSubmit = async (values) => {
    const responseData = await becomeAPartner(values);
    dispatch(setRequestStatus(responseData));
    dispatch(changePopUpStatus(true));
    onClose();
  };

  return (
    <ReactModal
      isOpen={showModal}
      onRequestClose={onClose}
      className="modal-content"
      overlayClassName="modal-overlay"
    >
      <div className=" flex justify-end  px-5 mt-3 ">
        <MdOutlineClose
          className="text-gray-600 hover:text-a050 h-6 w-6 cursor-pointer"
          onClick={onClose}
        />
      </div>
      <div
        className="bg-white flex flex-col justify-start  items-center px-5  md:mb-5 pb-5 md:pb-0
         w-full sm:max-w-sm  lg:max-w-[712px]  lg:h-auto 
          overflow-y-auto overflow-x-hidden  "
      >
        <div className="max-w-[336px] sm:max-w-full">
          <div className="flex  flex-col w-[320px] lg:w-full items-start justify-center">
            <h1 className="text-black prose-t24_bold lg:prose-t29_bold">
              {t("becomeAPartner2")}
            </h1>
            <span className="prose-t14_regular text-a070">
              {t("youCanReachUs")}
            </span>
          </div>
          <Formik
            initialValues={{
              companyName: "",
              category: "",
              email: "",
              phoneNumber: "",
              info: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form>
              <div className=" mt-4 flex flex-col lg:flex-row justify-center gap-4">
                <div className="flex flex-col">
                  <Field
                    type="text"
                    name="companyName"
                    placeholder={t("companyName")}
                    className={formInput}
                  />
                  <div className="min-h-[20px]">
                    <ErrorMessage
                      name="companyName"
                      component="div"
                      className={errorMessageStyle}
                    />
                  </div>
                </div>
                <div className="flex flex-col">
                  <Field
                    type="text"
                    name="category"
                    placeholder={t("serviceSegment")}
                    className={formInput}
                  />
                </div>
              </div>
              <div className=" mt-8 lg:mt-0 flex flex-col lg:flex-row justify-center gap-4">
                <div className="flex flex-col">
                  <Field
                    type="text"
                    name="email"
                    placeholder={t("yourEmail")}
                    className={formInput}
                  />
                  <div className="min-h-[20px]">
                    <ErrorMessage
                      name="email"
                      component="div"
                      className={errorMessageStyle}
                    />
                  </div>
                </div>
                <div className="flex flex-col">
                  <Field
                    type="text"
                    name="phoneNumber"
                    placeholder={t("phoneNumber")}
                    className={formInput}
                  />
                  <div className="min-h-[20px]">
                    <ErrorMessage
                      name="phoneNumber"
                      component="div"
                      className={errorMessageStyle}
                    />
                  </div>
                </div>
              </div>
              <div className="mb-8 mt-4 lg:mt-0 flex md:justify-start lg:justify-center">
                <div className="flex flex-col">
                  <Field
                    as="textarea"
                    name="info"
                    placeholder={t("additionalInformation")}
                    style={{ resize: "none" }}
                    className=" w-[335px] lg:w-[672px] h-[159px] bg-gray-100 rounded-xl p-3 focus:outline-none"
                  />
                  <div className="min-h-[20px]">
                    <ErrorMessage
                      name="info"
                      component="div"
                      className={errorMessageStyle}
                    />
                  </div>
                </div>
              </div>
              <div className="mb-2.5 flex justify-center lg:justify-center">
                <button type="submit" className={formButton}>
                  {t("submit1")}
                </button>
              </div>
            </Form>
          </Formik>
          <div className="w-full flex justify-center items-center lg:justify-center">
            <span className="prose-t14_regular text-center  text-[#5D5D5D]">
              {t("byContactingUsYou")}
              &nbsp;
              <a
                href="/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
                className="prose-t14_bold cursor-pointer text-black hover:text-a070"
              >
                {t("privacyPolicy1")}
              </a>
            </span>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

export default PartnersModal;
