import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Benefits from "../components/services/Benefits";
import ServiceList from "../components/services/ServiceList";
import SearchInput from "../components/searchInput/SearchInput";
import { useLocation } from "react-router-dom";

const Services = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const data = [
    { id: 1, name: "Cleaning", url: "/assets/services/1.png" },
    { id: 2, name: "Handiman", url: "/assets/services/2.png" },
    { id: 3, name: "Nurse", url: "/assets/services/3.png" },
    { id: 4, name: "Lecturer", url: "/assets/services/4.png" },
    { id: 5, name: "Babysitter", url: "/assets/services/5.png" },
    { id: 6, name: "Dogsitter", url: "/assets/services/6.png" },
    { id: 7, name: "Massage", url: "/assets/services/7.png" },
    { id: 8, name: "Psychologist", url: "/assets/services/8.png" },
    { id: 9, name: "Psychology", url: "logo1.png" },
    { id: 10, name: "Plumbing", url: "logo2.png" },
    { id: 11, name: "Cleaning", url: "logo3.png" },
    { id: 12, name: "Construction", url: "logo4.png" },
    { id: 13, name: "Electricity", url: "logo5.png" },
    { id: 14, name: "Barber", url: "logo6.png" },
    { id: 15, name: "Makeup", url: "logo6.png" },
    { id: 16, name: "Interior Design", url: "logo6.png" },
  ];

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const scrollToDiv = queryParams.get("serviceList");

    if (scrollToDiv === "true") {
      const targetDiv = document.querySelector("#scrollTarget");
      if (targetDiv) {
        targetDiv.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location.search]);

  return (
    <div className="flex flex-col  gap-[70px] pt-5 lg:pt-[122px] pb-[70px]">
      {/* Section 1 */}
      <div className="  flex flex-col md:items-center lg:flex-row justify-center items-start lg:gap-[119px]">
        <div className="  max-w-[520px] flex flex-col items-center lg:items-start justify-center lg:gap-4 px-2 lg:px-0 pb-[32px] lg:pb-0 lg:pt-5">
          <div className="prose-t36_bold lg:prose-t58_bold pb-[12px] flex flex-wrap justify-center lg:justify-start gap-3">
            <h2>{t("our")}</h2>
            <h2 className="text-primary"> {t("services1")} </h2>
          </div>
          <p className=" prose-t18_regular text-a070 text-center lg:text-start">
            {t("atMyServiceWeConnect")}
          </p>
        </div>
        <img
          alt="about"
          src="/assets/ourService.webp"
          className=" rounded-md"
        />
      </div>
      {/* Section 2 */}
      <div className="flex flex-col items-center gap-8">
        <div className=" max-w-[588px] flex flex-col items-center gap-4 ">
          <div className="  flex gap-2">
            <h2 className="prose-t32_regular lg:prose-t40_regular">
              {t("why")}
            </h2>
            <h2 className="text-primary prose-t32_bold lg:prose-t40_bold">
              {t("chooseUS")}
            </h2>
          </div>
          <p className="text-a070 text-center prose-t16_regular">
            {t("myServiceTakesPrideIn")}
          </p>
        </div>
        <Benefits />
      </div>
      <div className="flex flex-col items-center gap-8">
        <div className="max-w-[588px] flex flex-col items-center gap-4">
          <h2 className="prose-t32_regular lg:prose-t40_medium text-center ">
            {t("weProvide")}
          </h2>
          <p className="prose-t16_regular text-center  text-a070">
            {t("connectingYouWithTrusted")}
          </p>
        </div>
        <div id="scrollTarget" className="max-w-[1194px] flex flex-col gap-8">
          <SearchInput />
          <ServiceList seeMore={true} data={data} />
        </div>
      </div>
    </div>
  );
};

export default Services;
