import React, { useEffect, useState } from "react";
import styles from "./styles/ComingSoonCountdown.module.css"; // Import CSS module

const ComingSoonCountdown = () => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const countDownDate = new Date("Jan 1, 2025 00:00:00").getTime();

    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countDownDate - now;

      if (distance < 0) {
        clearInterval(interval);
      } else {
        setTimeLeft({
          days: Math.floor(distance / (1000 * 60 * 60 * 24)),
          hours: Math.floor(
            (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          ),
          minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
          seconds: Math.floor((distance % (1000 * 60)) / 1000),
        });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <section
      className={`${styles["coming-soon"]} ${styles["coming-soon--animated-gradient"]} py-20`}
    >
      {/* Bubbles */}
      <div className={styles["coming-soon__bubble"]}></div>
      <div className={styles["coming-soon__bubble"]}></div>
      <div className={styles["coming-soon__bubble"]}></div>
      <div className={styles["coming-soon__bubble"]}></div>
      <div className={styles["coming-soon__bubble"]}></div>

      <div className="max-w-5xl mx-auto px-6 lg:px-8 flex flex-col items-center justify-center text-center">
        <img
          src="/assets/header-logo.png"
          alt="Company Logo"
          className="mb-8 w-32 lg:w-48"
        />
        <h2 className="text-4xl lg:text-5xl text-a080 font-bold mb-8">
          Our Website is Coming Soon
        </h2>
        <p className="text-gray-700 text-lg lg:text-xl leading-relaxed mb-12">
          Book trustworthy services in one place{" "}
        </p>
        <div className="flex flex-wrap items-center justify-center gap-4 p-2">
          <div
            className={`${styles["coming-soon__time-block"]} bg-white rounded-full px-6 py-2 min-w-[120px]`}
          >
            <div className="font-bold text-xl text-primary">
              {timeLeft.days}d
            </div>
            <div className="text-xs uppercase text-gray-500">days</div>
          </div>
          <div
            className={`${styles["coming-soon__time-block"]} bg-white rounded-full px-6 py-2 min-w-[120px]`}
          >
            <div className="font-bold text-xl text-primary">
              {timeLeft.hours}h
            </div>
            <div className="text-xs uppercase text-gray-500">hours</div>
          </div>
          <div
            className={`${styles["coming-soon__time-block"]} bg-white rounded-full px-6 py-2 min-w-[120px]`}
          >
            <div className="font-bold text-xl text-primary">
              {timeLeft.minutes}m
            </div>
            <div className="text-xs uppercase text-gray-500">minutes</div>
          </div>
          <div
            className={`${styles["coming-soon__time-block"]} bg-white rounded-full px-6 py-2 min-w-[120px]`}
          >
            <div className="font-bold text-xl text-primary">
              {timeLeft.seconds}s
            </div>
            <div className="text-xs uppercase text-gray-500">seconds</div>
          </div>
        </div>
        <a
          href="https://www.linkedin.com/company/myservice-am/posts/?feedView=all"
          target="_blank"
          rel="noopener noreferrer"
          className="mt-8 text-gray-700 hover:underline"
        >
          Follow us on LinkedIn
        </a>
      </div>
    </section>
  );
};

export default ComingSoonCountdown;
