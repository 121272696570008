import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { IoSearch } from "react-icons/io5";

const SearchInput = () => {
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState(t("service")); // Initial state
  const [showButtons, setShowButtons] = useState(true);
  const location = useLocation();

  useEffect(() => {
    // Check if the path contains '/services' or '/partners', regardless of language
    const path = location.pathname.toLowerCase();

    setShowButtons(!(path.includes("/services") || path.includes("/partners")));

    if (path.includes("/services")) {
      setSelectedOption(t("service"));
    }
  }, [location.pathname, t]);

  useEffect(() => {
    // Reset selected option to "Service" on language change
    setSelectedOption(t("service"));
  }, [t]);

  const handleClick = (option) => {
    setSelectedOption(option);
  };

  // Determine the translation key for placeholder
  const getPlaceholderKey = () => {
    if (selectedOption === t("service")) {
      return "service1";
    } else if (selectedOption === t("provider")) {
      return "provider1";
    }
  };

  return (
    <div className="flex flex-col items-center gap-2.5">
      {/* Buttons */}
      {showButtons && (
        <div className="flex items-center justify-center gap-2 px-1 py-1 bg-white border-[1px] border-a010 rounded-[28px] shadow-md">
          {[t("service"), t("provider")].map((option) => (
            <span
              key={option}
              className={`h-[25px] prose-t12_medium px-2 w-[100px] flex items-center justify-center rounded-3xl ${
                selectedOption === option
                  ? "bg-ms2 text-white rounded-[28px] shadow-md "
                  : "bg-white text-a060 "
              } cursor-pointer`}
              onClick={() => handleClick(option)}
            >
              {option}
            </span>
          ))}
        </div>
      )}
      {/* Input */}
      <div
        className="shadow-md w-[366px] md:w-[464px] h-[64px] flex items-center 
      justify-center rounded-lg backdrop-blur-2xl bg-gradient-to-r from-black/20 to-black/10"
      >
        <div className="relative">
          <span className="absolute inset-y-0 left-0 flex items-center pl-2">
            <IoSearch className="h-5 w-5 text-gray-400" />
          </span>
          <input
            className="block w-[336px] md:w-[444px] h-[44px] pl-10 pr-4 py-2 focus:outline-none"
            placeholder={`${t("search")} ${t(getPlaceholderKey())}`} // Use the dynamic placeholder key
          />
        </div>
      </div>
    </div>
  );
};

export default SearchInput;
