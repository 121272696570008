import React from "react";
import { useTranslation } from "react-i18next";
import { MdOutlineClose } from "react-icons/md";
import { primaryFIxed } from "../../ui-elements/button";
import { useDispatch, useSelector } from "react-redux";
import {
  changePopUpStatus,
  selectIsOpenModal,
  selectRequestStatus,
  setRequestStatus,
} from "../../redux/slices/popUp/popUpSlice";
import {
  changeStatus,
  closeModal,
  selectModalStatus,
} from "../../redux/slices/loginModal/modalSlice";

const PopUpWindow = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isOpenPopUp = useSelector(selectIsOpenModal);
  const requestData = useSelector(selectRequestStatus);
  const isSignIn = useSelector(selectModalStatus);

  const handleCLose = () => {
    dispatch(changePopUpStatus(false));
    dispatch(setRequestStatus(null));

    if (isSignIn === "SignIn" && requestData.status === false) {
      return null;
    }

    dispatch(closeModal(false));
    dispatch(changeStatus(""));
  };

  return (
    isOpenPopUp && (
      <div className="fixed inset-0 flex justify-center items-center bg-gray-900 bg-opacity-50 z-50">
        {/* Popup container */}
        <div className="bg-white  shadow-md  rounded-lg w-11/12 max-w-lg  md:w-[500px] md:h-[268px] relative z-10">
          <button
            onClick={handleCLose}
            className="absolute top-2 right-2 p-2 text-black hover:text-gray-800 focus:outline-none "
          >
            <MdOutlineClose className="w-6 h-6" />
          </button>
          {/* Popup content */}
          <div className="flex flex-col justify-between items-center py-8 px-2">
            {requestData.status ? (
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.2 29.2L31.3 15.1L28.5 12.3L17.2 23.6L11.5 17.9L8.7 20.7L17.2 29.2ZM20 40C17.2333 40 14.6333 39.475 12.2 38.425C9.76667 37.375 7.65 35.95 5.85 34.15C4.05 32.35 2.625 30.2333 1.575 27.8C0.525 25.3667 0 22.7667 0 20C0 17.2333 0.525 14.6333 1.575 12.2C2.625 9.76667 4.05 7.65 5.85 5.85C7.65 4.05 9.76667 2.625 12.2 1.575C14.6333 0.525 17.2333 0 20 0C22.7667 0 25.3667 0.525 27.8 1.575C30.2333 2.625 32.35 4.05 34.15 5.85C35.95 7.65 37.375 9.76667 38.425 12.2C39.475 14.6333 40 17.2333 40 20C40 22.7667 39.475 25.3667 38.425 27.8C37.375 30.2333 35.95 32.35 34.15 34.15C32.35 35.95 30.2333 37.375 27.8 38.425C25.3667 39.475 22.7667 40 20 40ZM20 36C24.4667 36 28.25 34.45 31.35 31.35C34.45 28.25 36 24.4667 36 20C36 15.5333 34.45 11.75 31.35 8.65C28.25 5.55 24.4667 4 20 4C15.5333 4 11.75 5.55 8.65 8.65C5.55 11.75 4 15.5333 4 20C4 24.4667 5.55 28.25 8.65 31.35C11.75 34.45 15.5333 36 20 36Z"
                  fill="#34B349"
                />
              </svg>
            ) : (
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 30C20.5667 30 21.0417 29.8083 21.425 29.425C21.8083 29.0417 22 28.5667 22 28C22 27.4333 21.8083 26.9583 21.425 26.575C21.0417 26.1917 20.5667 26 20 26C19.4333 26 18.9583 26.1917 18.575 26.575C18.1917 26.9583 18 27.4333 18 28C18 28.5667 18.1917 29.0417 18.575 29.425C18.9583 29.8083 19.4333 30 20 30ZM18 22H22V10H18V22ZM20 40C17.2333 40 14.6333 39.475 12.2 38.425C9.76667 37.375 7.65 35.95 5.85 34.15C4.05 32.35 2.625 30.2333 1.575 27.8C0.525 25.3667 0 22.7667 0 20C0 17.2333 0.525 14.6333 1.575 12.2C2.625 9.76667 4.05 7.65 5.85 5.85C7.65 4.05 9.76667 2.625 12.2 1.575C14.6333 0.525 17.2333 0 20 0C22.7667 0 25.3667 0.525 27.8 1.575C30.2333 2.625 32.35 4.05 34.15 5.85C35.95 7.65 37.375 9.76667 38.425 12.2C39.475 14.6333 40 17.2333 40 20C40 22.7667 39.475 25.3667 38.425 27.8C37.375 30.2333 35.95 32.35 34.15 34.15C32.35 35.95 30.2333 37.375 27.8 38.425C25.3667 39.475 22.7667 40 20 40ZM20 36C24.4667 36 28.25 34.45 31.35 31.35C34.45 28.25 36 24.4667 36 20C36 15.5333 34.45 11.75 31.35 8.65C28.25 5.55 24.4667 4 20 4C15.5333 4 11.75 5.55 8.65 8.65C5.55 11.75 4 15.5333 4 20C4 24.4667 5.55 28.25 8.65 31.35C11.75 34.45 15.5333 36 20 36Z"
                  fill="#F02934"
                />
              </svg>
            )}
            <h2 className="prose-t20_medium text-black pt-8">
              {t(requestData.title)}
            </h2>
            <p className="prose-t14_regular text-a070 pt-2 pb-5 text-center">
              {t(requestData.description)}
            </p>
            <button className={primaryFIxed} onClick={handleCLose}>
              {t("ok")}
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default PopUpWindow;
